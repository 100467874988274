<template>
  <div>
    <br />
    <b-row>
      <b-col class="text-center">
        <img height="200px" src="../../assets/images/logo.png" />
      </b-col>
    </b-row>
    <b-row>
      <b-col class="text-center mt-2">
        <img height="50px" src="../../assets/images/title.png" />
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {};
</script>
<style>
</style>
